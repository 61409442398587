@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* width: 100%;
	height: 100%;
	overflow: hidden; */

  background-color: '#17151D';
}

body > div {
  /* height: 100%;
	overflow: scroll;
	-webkit-overflow-scrolling: touch; */
  background-color: '#2B2C31';
  overflow: hidden;
  transition: 'background-color 0.3s ease' !important;
}

/* div:hover {
	box-shadow: 0 2px 4px rgba(91, 154, 255, 0.242);
	transition: box-shadow 0.3s ease;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*:focus {
  outline: none;
  transition: 'background-color 0.3s ease' !important;
}

*:hover {
  transition: background-color 0.3s ease !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #2c343f;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #2c343f inset;
  transition: background-color 5000s ease-in-out 0s;
}

::selection {
  background: #0068ff;
  color: #fff;
  text-shadow: none;
}

.pdf-page-break {
  page-break-after: always;
  display: block;
  clear: both;
}

@media print {
  .pagebreak {
    page-break-before: always;
  }
}

img.responsive {
  width: 100%; /* This sets the width of the image to the width of its container */
  height: auto; /* This allows the height to adjust proportionally to the width */
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham-Book.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham-Bold.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham-Bold.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
